import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { Modal } from '../../../../shared/components/modal';

export type SplitMode = 'none' | 'split' | 'left' | 'right' | 'both';

const messages = (mode: SplitMode) => {
  switch (mode) {
    case 'split': return 'Dele eksisterende brikke?';
    case 'left': return 'Justere slutt dato på tidligere brikke?';
    case 'right': return 'Justere start dato på påfølgende brikke?';
    case 'both': return 'Justere start/sluttdato på tidligere og påfølgende brikke?';
    default: return '';
  }
};

export const SplitModal = ({
  open = false,
  mode = 'none',
  onClose = () => null,
  onSubmit = () => null,
}: {
    open?: boolean,
    mode: SplitMode,
    onClose?: () => void,
    onSubmit?: (isSplit: boolean) => void,
}) => {
  const message = messages(mode);
  const title = mode === 'split' ? 'Dele brikke?' : 'Justere brikke?';
  return (
    <Modal
      title={title}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      footer={(
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
          flexGrow: 1,

        }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onSubmit(false)}
          >
            Nei
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onSubmit(true)}
          >
            {mode === 'split' ? 'Del opp' : 'Juster'}
          </Button>
        </Box>
      )}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box sx={{}}>
          <Typography>{message}</Typography>
        </Box>
      </Box>
    </Modal>
  );
};
