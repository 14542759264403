import { EventApi } from '@fullcalendar/core';

export interface SplitEvent {
  id: string,
  startTime: Date,
  endTime: Date,
  parent: number|string,
}

export const eventToSplitEvent = (
  e: EventApi,
): SplitEvent | null => {
  if (!e) return null;
  if (!e.start) return null;
  if (!e.end) return null;
  const resourceId = e.getResources()[0].id;
  const startTime = e.start;
  const endTime = e.end;
  return {
    id: e.id,
    startTime,
    endTime,
    parent: parseInt(resourceId, 10) || resourceId,
  };
};
